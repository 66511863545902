

















import Vue from 'vue'
import { Store } from 'vuex'

import { rgbaConverter } from './helpers/helpers'

export default Vue.extend({
  name: 'FreeFieldsLabel',
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  computed: {
    storeState(): IState {
      return (this.$store as Store<IState>).state
    },
    isRequired(): boolean {
      return this.field.required
    },
    fieldNaming(): string {
      return this.field.label
    },
    labelColor(): string {
      return rgbaConverter(
        this.storeState.slidesConfig.option.textColor as IColor
      )
    },
    privacyCheckbox(): boolean {
      if (this.field.type === 'input' && this.field.inputType === 'checkbox') {
        return this.field.privacy
      }
      return false
    },
    labelDOMId(): string {
      return `${this.$store.state.clickFlowID}-field-label`
    },
    requiredDOMId(): string {
      return `${this.$store.state.clickFlowID}-field-label-required`
    }
  }
})
