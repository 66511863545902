























import Vue from 'vue'
import { Store } from 'vuex'
import { rgbaConverter, openModal } from './helpers/helpers'
import { findInitialVisibleSlideId } from './../store/state'

import Slide from './Slide.vue'

export default Vue.extend({
  name: 'OpenModal',
  components: { Slide },
  data() {
    return {
      defaultNextButton: true
    }
  },
  computed: {
    storeState(): IState {
      return (this.$store as Store<IState>).state
    },
    buttonStyle(): IButton {
      return this.storeState.slidesConfig.submitButton
    },
    buttonBackground(): string {
      return this.defaultNextButton
        ? rgbaConverter(this.buttonStyle.normalStyle.background.color as IColor)
        : rgbaConverter(this.buttonStyle.hoverStyle.background.color as IColor)
    },
    buttonTextColor(): string {
      return rgbaConverter(this.buttonStyle.normalStyle.textColor)
    },
    buttonBorder(): string {
      return `${
        this.buttonStyle.normalStyle.borderWidth
      }px solid ${rgbaConverter(this.buttonStyle.normalStyle.borderColor)}`
    },
    slide(): TSlideResult {
      return (this.$store as Store<IState>).getters.getSlideById(
        findInitialVisibleSlideId()
      )
    },
    isOpenedByCards(): boolean {
      return this.storeState.openModalByCards
    },
    buttonId(): string {
      return `${this.$store.state.clickFlowID}-open-modal-button`
    }
  },
  methods: {
    click() {
      if (this.storeState.openModalByCards) {
        this.nextSlide()
      }
      if (!this.storeState.showModal) {
        openModal()
      }
    },
    buttonHover() {
      this.defaultNextButton = !this.defaultNextButton
    },
    nextSlide() {
      this.$store.commit('visibleSlideId', this.slide.targetSlideId)
    }
  }
})
