









import Vue from 'vue'
import { Store } from 'vuex'

import { rgbaConverter } from './helpers/helpers'

export default Vue.extend({
  name: 'ModalFooter',
  computed: {
    store(): IState {
      return (this.$store as Store<IState>).state
    },
    footerBackground(): string {
      return rgbaConverter(this.store.footer.background.color as IColor)
    }
  }
})
