import { sendFormToChocobrain4 } from './../cB4FormPlugin'

export const configMock: IUserConfig = {
  openModalCallback: param => console.log(param),
  clickFlowFinishedCallback: appResult =>
    sendFormToChocobrain4({
      appResult,
      formId: 'Preisanfrage (Neues Formular)'
    }),
  openModalByCards: false,
  clickFlowID: 'msf-bestpellets',
  clickFlowTitle: 'Fordern Sie jetzt Ihr unverbindliches Angebot an!',
  clickFlowDescription: undefined,
  versionClickFlow: 1,
  pathnameBackupForm: '/kontakt',
  fontSource: undefined,
  slidesConfig: {
    slideBackground: { red: 255, green: 255, blue: 255, alpha: 1 },
    nextButton: {
      showOnSlide: true,
      disableWithoutInput: false,
      normalStyle: {
        background: {
          color: { red: 41, green: 178, blue: 41, alpha: 1 },
          image: { src: undefined, alt: undefined }
        },
        font: undefined,
        borderColor: { red: 41, green: 136, blue: 41, alpha: 1 },
        borderWidth: 1,
        textColor: { red: 255, green: 255, blue: 255, alpha: 1 }
      },
      hoverStyle: {
        background: {
          color: { red: 41, green: 136, blue: 41, alpha: 1 },
          image: { src: undefined, alt: undefined }
        },
        font: undefined,
        borderColor: { red: 41, green: 178, blue: 41, alpha: 1 },
        borderWidth: 0,
        textColor: { red: 255, green: 255, blue: 255, alpha: 1 }
      },
      label: 'Weiter'
    },
    submitButton: {
      normalStyle: {
        background: {
          color: { red: 198, green: 12, blue: 48, alpha: 1 },
          image: { src: undefined, alt: undefined }
        },
        font: undefined,
        borderColor: { red: 184, green: 79, blue: 103, alpha: 1 },
        borderWidth: 1,
        textColor: { red: 255, green: 255, blue: 255, alpha: 1 }
      },
      hoverStyle: {
        background: {
          color: { red: 184, green: 79, blue: 103, alpha: 1 },
          image: { src: undefined, alt: undefined }
        },
        font: undefined,
        borderColor: { red: 198, green: 12, blue: 48, alpha: 1 },
        borderWidth: 0,
        textColor: { red: 198, green: 12, blue: 48, alpha: 1 }
      }
    },
    question: {
      background: {
        color: { red: 44, green: 45, blue: 40, alpha: 0 },
        image: { src: undefined, alt: undefined }
      },
      font: undefined,
      borderColor: { red: 130, green: 134, blue: 140, alpha: 0 },
      borderWidth: 0,
      textColor: { red: 33, green: 37, blue: 41, alpha: 1 }
    },
    option: {
      background: {
        color: { red: 237, green: 237, blue: 237, alpha: 1 },
        image: { src: undefined, alt: undefined }
      },
      font: undefined,
      borderColor: { red: 41, green: 178, blue: 41, alpha: 1 },
      borderWidth: 8,
      textColor: { red: 33, green: 37, blue: 41, alpha: 1 }
    },
    title: {
      background: {
        color: { red: 202, green: 203, blue: 205, alpha: 1 },
        image: { src: undefined, alt: undefined }
      },
      font: undefined,
      borderColor: { red: 33, green: 34, blue: 45, alpha: 1 },
      borderWidth: 2,
      textColor: { red: 33, green: 37, blue: 41, alpha: 1 }
    }
  },
  head: {
    logo: {
      image: {
        src:
          'https://res.cloudinary.com/chocobrain/image/upload/fl_sanitize/v1554279063/7EXozRV3/best-Pellets_Logo.svg',
        alt: 'best:Pellets'
      },
      link: {
        src: 'https://www.best-pellets.de/',
        title: 'best:Pellets',
        target: '_self'
      }
    },
    closeButton: {
      normalStyle: {
        background: {
          color: { red: 255, green: 255, blue: 255, alpha: 1 },
          image: { src: undefined, alt: undefined }
        },
        font: undefined,
        borderColor: { red: 180, green: 180, blue: 180, alpha: 1 },
        borderWidth: 2,
        textColor: { red: 180, green: 180, blue: 180, alpha: 1 }
      },
      hoverStyle: {
        background: {
          color: { red: 255, green: 255, blue: 255, alpha: 1 },
          image: { src: undefined, alt: undefined }
        },
        font: undefined,
        borderColor: { red: 41, green: 178, blue: 41, alpha: 1 },
        borderWidth: 2,
        textColor: { red: 41, green: 178, blue: 41, alpha: 1 }
      },
      label: '&times;'
    },
    background: {
      color: { red: 255, green: 255, blue: 255, alpha: 1 },
      image: { src: undefined, alt: undefined }
    }
  },
  slides: [
    {
      position: 1,
      id: 'slide1',
      targetSlideId: 'slide2',
      titleContent: 'Wählen Sie bitte Ihre Produktart aus.',
      titleHint: undefined,
      type: 'select',
      answerOptions: [
        {
          id: 'looseGoods',
          label: 'Lose Ware',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1554378706/7EXozRV3/pellets%20Haufen',
            alt: 'Lose Ware'
          },
          selected: false,
          nextSlide: 'slide2'
        },
        {
          id: 'baggedGoods',
          label: 'Sackware',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1558090241/7EXozRV3/bP_Sack_Einzeln',
            alt: 'Sackware'
          },
          selected: false,
          nextSlide: 'slide3'
        },
        {
          id: 'woodBriquettes',
          label: 'Holzbriketts',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1607330370/7EXozRV3/Holzbriketts%20Hart%201',
            alt: 'Holzbriketts'
          },
          selected: false,
          nextSlide: 'slide4'
        }
      ],
      multipleSelect: false,
      required: true,
      formFieldName: 'Produktart'
    },
    {
      position: 2,
      id: 'slide2',
      targetSlideId: 'slide5',
      titleContent: 'Wieviele Tonnen benötigen Sie?',
      titleHint: undefined,
      type: 'freeField',
      columns: 6,
      fields: [
        {
          id: 'tons',
          required: true,
          label: 'Tonnen',
          hint: undefined,
          positionColumn: 2,
          positionRow: 1,
          columnSpan: 4,
          type: 'input',
          inputType: 'range',
          max: 15,
          min: 0.5,
          step: 0.5,
          value: '0',
          base: 'Tonnen',
          formFieldName: 'AnzahlTonnen'
        }
      ]
    },
    {
      position: 3,
      id: 'slide3',
      targetSlideId: 'slide8',
      titleContent: 'Wieviele Paletten benötigen Sie?',
      titleHint: undefined,
      type: 'select',
      answerOptions: [
        {
          id: 'onePalette',
          label: '1 Palette',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1619792850/7EXozRV3/Ganze_Palette_-_Sackware.jpg',
            alt: '1P'
          },
          selected: false,
          nextSlide: undefined
        },
        {
          id: 'twoPalettes',
          label: '2 Paletten',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1620206787/7EXozRV3/2_Ganze_Palette_Sackware.jpg',
            alt: '2P'
          },
          selected: false,
          nextSlide: undefined
        },
        {
          id: 'threePalettes',
          label: '3 Paletten',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1620206779/7EXozRV3/3_Ganze_Palette_Sackware.jpg',
            alt: '3P'
          },
          selected: false,
          nextSlide: undefined
        },
        {
          id: 'overFourPalettes',
          label: '>4 Paletten',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1620206783/7EXozRV3/4_Ganze_Palette_Sackware.jpg',
            alt: '>4P'
          },
          selected: false,
          nextSlide: undefined
        }
      ],
      multipleSelect: false,
      required: true,
      formFieldName: 'AnzahlPaletten'
    },
    {
      position: 4,
      id: 'slide4',
      targetSlideId: 'slide6',
      titleContent: 'Wählen Sie ihren Härtegrad aus.',
      titleHint: undefined,
      type: 'select',
      answerOptions: [
        {
          id: 'hard',
          label: 'Hart',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1619792836/7EXozRV3/Briketts_hart.jpg',
            alt: 'Hart'
          },
          selected: false,
          nextSlide: 'slide6'
        },
        {
          id: 'soft',
          label: 'Weich',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1619792836/7EXozRV3/Briketts_weich.jpg',
            alt: 'Weich'
          },
          selected: false,
          nextSlide: 'slide7'
        }
      ],
      multipleSelect: false,
      required: true,
      formFieldName: 'Produktwahl'
    },
    {
      position: 5,
      id: 'slide5',
      targetSlideId: 'slide8',
      titleContent: 'Pellets mit PowerPlus?',
      titleHint: undefined,
      type: 'select',
      answerOptions: [
        {
          id: 'yesPowerPlus',
          label: 'Ja',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1619793445/7EXozRV3/Seite_3_lose_Ware_ja.jpg',
            alt: 'Ja'
          },
          selected: false,
          nextSlide: undefined
        },
        {
          id: 'noPowerPlus',
          label: 'Nein',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1619793444/7EXozRV3/Seite_3_lose_Ware_nein.png',
            alt: 'Nein'
          },
          selected: false,
          nextSlide: undefined
        }
      ],
      multipleSelect: false,
      required: true,
      formFieldName: 'PowerPlus'
    },
    {
      position: 6,
      id: 'slide6',
      targetSlideId: 'slide9',
      titleContent: 'Wieviele Paletten benötigen Sie?',
      titleHint: undefined,
      type: 'select',
      answerOptions: [
        {
          id: 'onePaletteHard',
          label: '1 Palette',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1619792846/7EXozRV3/Palette_hart.jpg',
            alt: '1P'
          },
          selected: false,
          nextSlide: undefined
        },
        {
          id: 'twoPalettesHard',
          label: '2 Paletten',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1620025002/7EXozRV3/2_Paletten_hart.jpg',
            alt: '2P'
          },
          selected: false,
          nextSlide: undefined
        },
        {
          id: 'threePalettesHard',
          label: '3 Paletten',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1620025010/7EXozRV3/3_Paletten_hart.jpg',
            alt: '3P'
          },
          selected: false,
          nextSlide: undefined
        },
        {
          id: 'overFourPalettesHard',
          label: '>4 Paletten',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1620025008/7EXozRV3/4_Paletten_hart.jpg',
            alt: '>4P'
          },
          selected: false,
          nextSlide: undefined
        }
      ],
      multipleSelect: false,
      required: true,
      formFieldName: 'AnzahlPaletten'
    },
    {
      position: 7,
      id: 'slide7',
      targetSlideId: 'slide9',
      titleContent: 'Wieviele Paletten benötigen Sie?',
      titleHint: undefined,
      type: 'select',
      answerOptions: [
        {
          id: 'onePaletteSoft',
          label: '1 Palette',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1619792846/7EXozRV3/Palette_weich.jpg',
            alt: '1P'
          },
          selected: false,
          nextSlide: undefined
        },
        {
          id: 'twoPalettesSoft',
          label: '2 Paletten',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1620025009/7EXozRV3/2_Paletten_weich.jpg',
            alt: '2P'
          },
          selected: false,
          nextSlide: undefined
        },
        {
          id: 'threePalettesSoft',
          label: '3 Paletten',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1620025004/7EXozRV3/3_Paletten_weich.jpg',
            alt: '3P'
          },
          selected: false,
          nextSlide: undefined
        },
        {
          id: 'overFourPalettesSoft',
          label: '>4 Paletten',
          hint: undefined,
          image: {
            src:
              'https://res.cloudinary.com/chocobrain/image/upload/c_scale,dpr_auto,f_auto,q_auto,w_480/v1620025008/7EXozRV3/4_Paletten_weich.jpg',
            alt: '>4P'
          },
          selected: false,
          nextSlide: undefined
        }
      ],
      multipleSelect: false,
      required: true,
      formFieldName: 'AnzahlPaletten'
    },
    {
      position: 8,
      id: 'slide8',
      targetSlideId: 'slide10',
      titleContent:
        'Wir benötigen noch ein paar letzte Informationen von Ihnen.',
      titleHint: undefined,
      type: 'freeField',
      columns: 2,
      fields: [
        {
          id: 'firstName',
          required: true,
          label: 'Vorname',
          hint: undefined,
          autocomplete: 'given-name',
          positionColumn: 1,
          positionRow: 1,
          columnSpan: 1,
          type: 'input',
          inputType: 'text',
          value: undefined,
          maxlength: 24,
          minlength: 0,
          placeholder: undefined,
          size: undefined,
          formFieldName: 'Vorname'
        },
        {
          id: 'surname',
          required: true,
          label: 'Nachname',
          hint: undefined,
          autocomplete: 'family-name',
          positionColumn: 2,
          positionRow: 1,
          columnSpan: 1,
          type: 'input',
          inputType: 'text',
          value: undefined,
          maxlength: 24,
          minlength: 0,
          placeholder: undefined,
          size: undefined,
          formFieldName: 'Nachname'
        },
        {
          id: 'street',
          required: true,
          label: 'Straße und Nr.',
          hint: undefined,
          autocomplete: 'address-level3',
          positionColumn: 1,
          positionRow: 2,
          columnSpan: 2,
          type: 'input',
          inputType: 'text',
          value: undefined,
          maxlength: 32,
          minlength: 0,
          placeholder: undefined,
          formFieldName: 'Straße',
          pattern: undefined
        },
        {
          id: 'postcode',
          required: true,
          label: 'PLZ',
          hint: undefined,
          autocomplete: 'postal-code',
          positionColumn: 1,
          positionRow: 3,
          columnSpan: 1,
          type: 'input',
          inputType: 'number',
          value: undefined,
          max: 99999,
          min: 0,
          placeholder: undefined,
          formFieldName: 'Postleitzahl',
          step: 1
        },
        {
          id: 'city',
          required: true,
          label: 'Ort',
          hint: undefined,
          autocomplete: 'address-level2',
          positionColumn: 2,
          positionRow: 3,
          columnSpan: 1,
          type: 'input',
          inputType: 'text',
          value: undefined,
          maxlength: 32,
          minlength: 0,
          placeholder: undefined,
          formFieldName: 'Ort',
          pattern: undefined
        },
        {
          id: 'phone',
          required: true,
          label: 'Telefonnummer',
          hint: undefined,
          autocomplete: 'tel',
          positionColumn: 1,
          positionRow: 4,
          columnSpan: 1,
          type: 'input',
          inputType: 'tel',
          value: undefined,
          maxlength: 24,
          minlength: 0,
          placeholder: undefined,
          formFieldName: 'Telefonnummer',
          size: undefined
        },
        {
          id: 'mobilePhone',
          required: false,
          label: 'Mobilnummer',
          hint: undefined,
          autocomplete: 'mobilephone',
          positionColumn: 2,
          positionRow: 4,
          columnSpan: 1,
          type: 'input',
          inputType: 'tel',
          value: undefined,
          maxlength: 24,
          minlength: 0,
          placeholder: undefined,
          formFieldName: 'Mobilnummer',
          size: undefined
        },
        {
          id: 'email',
          required: true,
          label: 'Mailadresse',
          hint: undefined,
          autocomplete: 'email',
          positionColumn: 1,
          positionRow: 5,
          columnSpan: 1,
          type: 'input',
          inputType: 'email',
          value: undefined,
          maxlength: 56,
          minlength: 6,
          multiple: false,
          placeholder: undefined,
          formFieldName: 'Email',
          size: undefined
        },
        {
          id: 'moreInformations',
          required: false,
          label: 'Anmerkungen',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 5,
          columnSpan: 1,
          type: 'textarea',
          value: undefined,
          cols: 40,
          rows: 7,
          placeholder: undefined,
          formFieldName: 'WeitereInformationen'
        },
        {
          id: 'submit',
          required: false,
          label: 'Absenden',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 1,
          positionRow: 6,
          columnSpan: 2,
          type: 'input',
          inputType: 'submit',
          formtarget: undefined,
          formFieldName: 'Submit'
        }
      ]
    },
    {
      position: 9,
      id: 'slide9',
      targetSlideId: 'slide10',
      titleContent:
        'Wir benötigen noch ein paar letzte Informationen von Ihnen.',
      titleHint: undefined,
      type: 'freeField',
      columns: 2,
      fields: [
        {
          id: 'firstNameBriquettes',
          required: true,
          label: 'Vorname',
          hint: undefined,
          autocomplete: 'given-name',
          positionColumn: 1,
          positionRow: 1,
          columnSpan: 1,
          type: 'input',
          inputType: 'text',
          value: undefined,
          maxlength: 24,
          minlength: 0,
          placeholder: undefined,
          size: undefined,
          formFieldName: 'Vorname'
        },
        {
          id: 'surnameBriquettes',
          required: true,
          label: 'Nachname',
          hint: undefined,
          autocomplete: 'family-name',
          positionColumn: 2,
          positionRow: 1,
          columnSpan: 1,
          type: 'input',
          inputType: 'text',
          value: undefined,
          maxlength: 24,
          minlength: 0,
          placeholder: undefined,
          size: undefined,
          formFieldName: 'Nachname'
        },
        {
          id: 'streetBriquettes',
          required: true,
          label: 'Straße und Nr.',
          hint: undefined,
          autocomplete: 'street-address',
          positionColumn: 1,
          positionRow: 2,
          columnSpan: 2,
          type: 'input',
          inputType: 'text',
          value: undefined,
          maxlength: 32,
          minlength: 0,
          placeholder: undefined,
          formFieldName: 'Straße',
          pattern: undefined
        },
        {
          id: 'postcodeBriquettes',
          required: true,
          label: 'PLZ',
          hint: 'Auslieferung aktuell nur in der PLZ 7XXXX',
          autocomplete: 'postal-code',
          positionColumn: 1,
          positionRow: 3,
          columnSpan: 1,
          type: 'input',
          inputType: 'number',
          value: undefined,
          max: 79999,
          min: 70000,
          placeholder: undefined,
          formFieldName: 'Postleitzahl',
          step: 1
        },
        {
          id: 'cityBriquettes',
          required: true,
          label: 'Ort',
          hint: undefined,
          autocomplete: 'address-level2',
          positionColumn: 2,
          positionRow: 3,
          columnSpan: 1,
          type: 'input',
          inputType: 'text',
          value: undefined,
          maxlength: 32,
          minlength: 0,
          placeholder: undefined,
          formFieldName: 'Ort',
          pattern: undefined
        },
        {
          id: 'phoneBriquettes',
          required: true,
          label: 'Telefonnummer',
          hint: undefined,
          autocomplete: 'tel',
          positionColumn: 1,
          positionRow: 4,
          columnSpan: 1,
          type: 'input',
          inputType: 'tel',
          value: undefined,
          maxlength: 24,
          minlength: 0,
          placeholder: undefined,
          formFieldName: 'Telefonnummer',
          size: undefined
        },
        {
          id: 'mobilePhoneBriquettes',
          required: false,
          label: 'Mobilnummer',
          hint: undefined,
          autocomplete: 'mobilephone',
          positionColumn: 2,
          positionRow: 4,
          columnSpan: 1,
          type: 'input',
          inputType: 'tel',
          value: undefined,
          maxlength: 24,
          minlength: 0,
          placeholder: undefined,
          formFieldName: 'Mobilnummer',
          size: undefined
        },
        {
          id: 'emailBriquettes',
          required: true,
          label: 'Mailadresse',
          hint: undefined,
          autocomplete: 'email',
          positionColumn: 1,
          positionRow: 5,
          columnSpan: 1,
          type: 'input',
          inputType: 'email',
          value: undefined,
          maxlength: 56,
          minlength: 6,
          multiple: false,
          placeholder: undefined,
          formFieldName: 'Email',
          size: undefined
        },
        {
          id: 'moreInformationsBriquettes',
          required: false,
          label: 'Anmerkungen',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 5,
          columnSpan: 1,
          type: 'textarea',
          value: undefined,
          cols: 40,
          rows: 7,
          placeholder: undefined,
          formFieldName: 'WeitereInformationen'
        },
        {
          id: 'submitBriquettes',
          required: false,
          label: 'Absenden',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 1,
          positionRow: 6,
          columnSpan: 2,
          type: 'input',
          inputType: 'submit',
          formtarget: undefined,
          formFieldName: 'Submit'
        }
      ]
    },
    {
      position: 10,
      id: 'slide10',
      targetSlideId: undefined,
      titleContent: 'Vielen Dank für Ihre Anfrage!',
      titleHint: 'Sie können die App nun schließen.',
      type: 'freeField',
      columns: 3,
      fields: []
    }
  ],
  footer: {
    logos: undefined,
    background: {
      color: { red: 255, green: 255, blue: 255, alpha: 1 },
      image: undefined
    },
    font: undefined
  }
}
