export const formResults = (slides: TSlideResult[]): IFormResult[] => {
  const selectResults: IFormResult[] = slides
    .filter(slide => slide.type === 'select')
    .map(slide => {
      return {
        name: (slide as ISelectSlide).formFieldName,
        type: 'text',
        value: slide.optionsResult
          .map(optionResult => optionResult.reply)
          .filter(reply => reply !== '')
          .join(', ')
      }
    })

  const freeFieldsResult: IFormResult[] = slides
    .filter(slide => slide.type === 'freeField')
    .flatMap(freeFieldSlide => {
      return (freeFieldSlide.optionsResult as (TField & {
        reply: string
      })[])
        .filter(
          optionResult =>
            !(
              optionResult.type === 'input' &&
              optionResult.inputType === 'file' &&
              optionResult.reply === ''
            )
        )
        .map(optionResult => {
          return {
            name: optionResult.formFieldName,
            type:
              optionResult.type === 'input' && optionResult.inputType === 'file'
                ? 'file'
                : 'text',
            value: optionResult.reply
          }
        })
    })

  const appResults: IFormResult[] = [...selectResults, ...freeFieldsResult]

  let formResults: IFormResult[] = []
  for (let i = 0; i < appResults.length; i++) {
    const element = appResults[i]
    const checkFormField = formResults.find(
      selectField => selectField.name === element.name
    )
    if (checkFormField === undefined) formResults.push(element)
    else if (element.value !== '') {
      formResults.filter(selectField => selectField.name !== element.name)
      formResults.push(element)
    }
  }

  return formResults
}

export const formData = (param: {
  formResults: IFormResult[]
  $form: HTMLFormElement
}): FormData => {
  const $elements = Array.from(param.$form.elements)

  const securityParamNames: string[] = [
    'authenticity_token',
    'page_uuid',
    'form_uuid',
    'e_mail'
  ]
  const securityParams: IFormResult[] = securityParamNames.map(
    securityParamName => {
      const $element = $elements.find(
        element => element.getAttribute('name') === securityParamName
      ) as HTMLInputElement | null
      if ($element === null) throw new Error('Element not found!')

      return {
        name: securityParamName,
        type: 'text',
        value: $element.value
      }
    }
  )
  const formParams: IFormResult[] = [...securityParams, ...param.formResults]

  const formData = new FormData()
  formParams.map(formParam => formData.append(formParam.name, formParam.value))
  return formData
}

export const sendFormToChocobrain4 = (param: {
  appResult: IResult
  formId: string
}): Promise<Response> => {
  const $form = document.getElementById(param.formId) as HTMLFormElement | null

  if ($form === null) throw new Error(`Form ${param.formId} not found!`)
  const url = $form.getAttribute('action')
  if (url === null) throw new Error('actionUrl not found!')

  const body = formData({
    formResults: formResults(param.appResult.slides),
    $form
  })
  return fetch(url, { method: 'POST', body })
}
