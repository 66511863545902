





























import Vue from 'vue'
import { Store } from 'vuex'

import { rgbaConverter } from './helpers/helpers'

import ProgressBar from './ProgressBar.vue'
import NextButton from './NextButton.vue'
import Slide from './Slide.vue'

export default Vue.extend({
  name: 'ModalBody',
  components: { ProgressBar, NextButton, Slide },
  computed: {
    store(): IState {
      return (this.$store as Store<IState>).state
    },
    visibleSlideId(): string {
      return this.store.visibleSlideId
    },
    visibleSlide(): TSlides {
      return (this.$store as Store<IState>).getters.getSlideById(
        this.visibleSlideId
      )
    },
    showNextButton(): boolean {
      return (
        ((((this.visibleSlide.type === 'select' &&
          this.visibleSlide.multipleSelect) ||
          this.visibleSlide.type === 'freeField') &&
          this.visibleSlide.targetSlideId != null) ||
          (this.showNextButtonOnSingleSelect === true &&
            this.visibleSlide.targetSlideId != null)) &&
        this.isSubmitSlide === false
      )
    },
    showNextButtonOnSingleSelect(): boolean {
      return this.store.slidesConfig.nextButton.showOnSlide
    },
    bodyBackground(): string {
      return rgbaConverter(this.store.slidesConfig.slideBackground)
    },
    modalTitle(): string {
      return this.store.clickFlowTitle
    },
    modalHint(): string {
      return this.store.clickFlowDescription
        ? this.store.clickFlowDescription
        : ''
    },
    hasHint(): boolean {
      return this.modalHint !== ''
    },
    titleColor(): string {
      return rgbaConverter(this.store.slidesConfig.title.textColor as IColor)
    },
    isSubmitSlide(): boolean {
      if (this.visibleSlide.type === 'freeField') {
        return this.visibleSlide.fields.some(
          field => field.type === 'input' && field.inputType === 'submit'
        )
      }
      return false
    },
    storeState(): IState {
      return (this.$store as Store<IState>).state
    },
    slides(): ISlideConfig[] {
      return this.storeState.slides
    },
    titleDOMId(): string {
      return `${this.$store.state.clickFlowID}-modal-title`
    },
    hintDOMId(): string {
      return `${this.$store.state.clickFlowID}-modal-hint`
    },
    bodyDOMId(): string {
      return `${this.$store.state.clickFlowID}-body-content`
    }
  }
})
