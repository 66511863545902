











import Vue from 'vue'

import Modal from './Modal.vue'
import Slide from './Slide.vue'
import OpenModal from './OpenModal.vue'

export default Vue.extend({
  name: 'App',
  components: { Modal, Slide, OpenModal },
  computed: {}
})
