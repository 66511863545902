const findSlides = (slides: TSlides[]): TSlideResult[] =>
  slides.map(slide => ({
    ...slide,
    ...{
      optionsResult: [
        ...(slide.type === 'select'
          ? slide.answerOptions.map(answerOption => ({
            ...answerOption,
            ...{ reply: findCardReply(answerOption) }
          }))
          : slide.fields.map(field => ({
            ...field,
            ...{ reply: findFieldReply(field) }
          })))
      ]
    }
  }))

const findFieldReply = (field: TField): TOptionResult['reply'] => {
  switch (field.type) {
    case 'textarea':
      return findValue(field)
    case 'select':
      return findOptionReply(field)
    case 'input':
      switch (field.inputType) {
        case 'checkbox':
          switch (field.privacy) {
            case true:
              return findPrivacyReply(field)
            case false:
              return findOptionReply(field)
          }
        case 'radio':
          return findOptionReply(field)
        case 'email':
          return findValue(field)
        case 'number':
          return findNumber(field)
        case 'tel':
          return findValue(field)
        case 'text':
          return findValue(field)
        case 'range':
          return findRange(field)
        case 'file':
          return ''
        case 'submit':
          return ''
        default:
          throw new Error('Could not match field inputType.')
      }
    default:
      throw new Error('Could not match field type.')
  }
}

const findPrivacyReply = (field: ICheckbox): TOptionResult['reply'] =>
  field.options.find(option => option.checked === true) != undefined ? "on" : ""

const findOptionReply = (
  field: ISelectField | ICheckbox | IRadio
): TOptionResult['reply'] =>
  field.options
    .filter(option => option.checked === true)
    .map(option => option.label)
    .join(', ')

const findRange = (field: IRange): TOptionResult['reply'] =>
  field.value ? field.value : (field.max / 2).toString()

const findValue = (
  field: IEMail | ITel | IText | ITextareaField
): TOptionResult['reply'] => (field.value ? field.value : '')

const findNumber = (field: INumber): TOptionResult['reply'] =>
  field.value ? field.value.toString() : ''

const findCardReply = (answerOption: IAnswerOption): TOptionResult['reply'] =>
  answerOption.selected ? answerOption.label : ''

export const configToEmptyResult = (
  config: Readonly<IUserConfig>
): IResult => ({
  ...config,
  ...{ slides: findSlides(config.slides) }
})
