




















import Vue from 'vue'
import { Store } from 'vuex'

import { rgbaConverter, closeModal } from './helpers/helpers'
import {
  validateSlide,
  unfilledRequiredFields
} from './helpers/validateHelpers'

export default Vue.extend({
  name: 'FreeFieldSubmit',
  props: {
    field: {
      type: Object,
      required: true
    },
    slideId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      defaultStyle: true
    }
  },
  computed: {
    storeState(): IState {
      return (this.$store as Store<IState>).state
    },
    slide(): TSlideResult {
      return (this.$store as Store<IState>).getters.getSlideById(this.slideId)
    },
    submitButton(): IButton {
      return this.storeState.slidesConfig.submitButton
    },
    buttonBackground(): string {
      if (!validateSlide(this.slide)) {
        return '#DDD'
      }
      return this.defaultStyle
        ? rgbaConverter(
            this.submitButton.normalStyle.background.color as IColor
          )
        : rgbaConverter(this.submitButton.hoverStyle.background.color as IColor)
    },
    buttonTextColor(): string {
      if (!validateSlide(this.slide)) {
        return '#999'
      }
      return rgbaConverter(this.submitButton.normalStyle.textColor)
    },
    buttonBorder(): string {
      return `${
        this.submitButton.normalStyle.borderWidth
      }px solid ${rgbaConverter(this.submitButton.normalStyle.borderColor)}`
    },
    disOrEnabledButton(): string {
      return validateSlide(this.slide)
        ? 'cfa-slide-submit-button-enabled'
        : 'cfa-slide-submit-button-disabled'
    },
    fieldId(): string {
      return `${this.$store.state.clickFlowID}-submit-${this.field.id}`
    },
    disableButton(): boolean {
      return validateSlide(this.slide)
    }
  },
  methods: {
    async click() {
      // Needed to disable Button
      if (validateSlide(this.slide)) {
        const promise = await this.storeState
          .clickFlowFinishedCallback(this.storeState)
          .then(response => {
            if (response.status === 200) {
              const slide = this.$store.getters.getSlideById(
                this.slideId
              ) as TSlides
              if (slide.targetSlideId) {
                this.$store.commit('visibleSlideId', slide.targetSlideId)
              } else {
                closeModal()
              }
            } else {
              console.error(
                `CF_ID: ${this.storeState.clickFlowID}; STATUSCODE: ${response.status}; STATUSTEXT: ${response.statusText}; HEADERS: ${response.headers}`
              )
              alert(
                'Mit Ihrer Verbindung ist ein technisches Problem aufgetreten. Sie werden auf unser Kontaktformular weitergeleitet. Bitte wenden Sie sich über das Kontaktformular an uns.'
              )
              closeModal()
              window.location.href = `${window.location.origin}${this.storeState.pathnameBackupForm}`
            }
          })
          .catch(response => {
            console.error(
              `CF_ID: ${this.storeState.clickFlowID}; ERROR: ${response.error}`
            )
            alert(
              'Mit Ihrer Verbindung ist ein technisches Problem aufgetreten. Sie werden auf unser Kontaktformular weitergeleitet. Bitte wenden Sie sich über das Kontaktformular an uns.'
            )
            closeModal()
            window.location.href = `${window.location.origin}${this.storeState.pathnameBackupForm}`
          })
      } else {
        const unfilledFieldsList = unfilledRequiredFields(this.slide).map(
          unfilledRequiredField => '\n - ' + unfilledRequiredField
        )
        alert(`Bitte füllen Sie folgende Pflichtfelder aus:${unfilledFieldsList}`)
      }
    },
    submitButtonHover() {
      this.defaultStyle = !this.defaultStyle
    }
  }
})
