


























import Vue from 'vue'
import { Store } from 'vuex'

import { rgbaConverter } from './helpers/helpers'

export default Vue.extend({
  name: 'ProgressBar',
  props: {
    slideId: {
      type: String,
      required: true
    }
  },
  computed: {
    storeState(): IState {
      return (this.$store as Store<IState>).state
    },
    probarDescription(): string {
      // return "";
      return 'Fortschritt'
      // return `Schritt: ${this.currentSlide.position}/${this.storeState.slides.length}`
    },
    probarPercentage(): string {
      return `${this.progress}%`
    },
    progress(): string {
      return (
        (this.currentSlide.position / this.storeState.slides.length) *
        100
      ).toFixed(0)
    },
    barProgress(): string {
      return `${this.progress}%`
    },
    currentSlide(): TSlideResult {
      return (this.$store as Store<IState>).getters.getSlideById(this.slideId)
    },
    darkColor(): string {
      return rgbaConverter(
        this.storeState.slidesConfig.nextButton.normalStyle.background
          .color as IColor
      )
    },
    lightColor(): string {
      return rgbaConverter(
        this.storeState.slidesConfig.option.background.color as IColor
      )
    },
    probarId(): string {
      return `${this.$store.state.clickFlowID}-probar-bar`
    },
    probarBarId(): string {
      return `${this.$store.state.clickFlowID}-probar-progress-bar`
    },
    probarDescriptionId(): string {
      return `${this.$store.state.clickFlowID}-probar-description`
    }
  }
})
